import "./productdetails.css";
import {useContext, useEffect, useState} from "react";
import { useNavigate,useParams } from 'react-router-dom';
import {StoreContext} from "../../context/StoreContext";
import {Product} from "../../components/type/Product";
import { assets } from "../../assets/assets";
import ProductAdvice from "../../components/ProductAdvice/ProductAdvice";



const ProductDetails =  () => {

    const {products,cartItems,setToCart,addToCart,subFromCart} = useContext(StoreContext);
    
    const navigate = useNavigate();
    const { id } = useParams();
    const [product, setProduct] = useState<Product>({id:0,name:"",price:0,description:"",quantity:0,image:"",categories:[]});

    const getProduct = (id:any) => {
        const prodFind = products.find((product:Product) => product.id === Number(id));
        setProduct(prodFind)
        if(!prodFind) {
            console.log("Product not found");
            navigate("/produits");
        }
    }


    useEffect(() => {

        if (products.length > 0) {
            getProduct(id);
        }
    }, [products,id]);

    return(
        <div className="product-details">
            <button className="product-details-exit" onClick={()=>navigate("/produits")}> <img src={assets.back} alt="Retour"/>
            Retour
            </button>
            <div className="product-details-header">
                <img src={product?.image} alt={product?.name} />
                <div className="product-details-infos">
                    <h1>{product?.name}</h1>
                    <div className="product-details-price-nb">
                        <p className="product-details-number">({product?.quantity == null ?"Vendu à l'unité" : "Vendu par boîte de "+ product?.quantity })</p>
                        <p className="product-details-price">{product?.price}€</p>
                    </div>
                    <p className="product-details-description">{product?.description}</p>

                    <div className="product-details-quantity">
                        <img onClick={()=>subFromCart(product.id)} src={assets.remove_red} alt="decrease"/>
                        <input min="0" max="200" type="number" onChange={(e)=>{if(e.target.value !=="" && Number(e.target.value)>=0){setToCart(product.id, Number(e.target.value))}}} value={cartItems[product?.id] || 0}></input>
                        <img onClick={()=>addToCart(product.id)} src={assets.add_green} alt="increase"/>
                    </div>
                    <button onClick={()=>navigate("/panier")}>Voir le panier</button>
                </div>
            </div>
            <div className="product-details-extra">
                 {product?.ingredients && product?.ingredients.length >0 && 
                 <div className="product-details-box">
                    <h2>Ingrédients</h2>
                    <hr/>
                    <div className="product-details-ingredients">
                        {product?.ingredients.map((ingredient: string, index: number)=>{
                            return <p key={index}>{ingredient}</p>
                        })}
                    </div>
                </div>}
                {product?.advices && product?.advices.length >0 && 
                <div className="product-details-box">
                    <h2>Conseils</h2>
                    <hr/>
                    <div className="product-details-advices">
                        {product?.advices.map((advice: string, index: number)=>{
                            return <p key={index}>{advice}</p>
                        })}
                    </div>
                </div>}
            </div>
                    

            <h2 className="product-advice-list-title">Produits similaires</h2>
            <hr/>
            <div className="product-advice-list">
            {products.filter(
                (p: Product) =>
                p.id !== product.id && // Exclure le produit actuel
                p.categories.filter(category => product.categories.includes(category)).length >= 2 // Vérifie au moins 2 catégories en commun
            )
            .map((filteredProduct: Product, index: number) => (
                <ProductAdvice key={filteredProduct.id} product={filteredProduct} />
            ))}
            </div>
        </div>
    )
}

export default ProductDetails;