import {useContext, useEffect, useState} from 'react'
import  "./navbar.css"
import {assets} from '../../assets/assets'
import {Link, useLocation} from 'react-router-dom';
import { StoreContext } from '../../context/StoreContext';


const Navbar =()=>{
    const location = useLocation();   
    const {cartItems} = useContext(StoreContext);

    const [showMobileNavbar, setShowMobileNavbar]= useState<boolean>(false);
    const [shadowNavbar, setShadowNavbar]= useState<boolean>(false);

    const handleResize = () => {
        if (window.innerWidth > 200) {
            setShowMobileNavbar(false);
        }
    };

    const handleScroll = () => {
        if (window.scrollY > 5) {
            setShadowNavbar(true);
        }else{
            setShadowNavbar(false);
        }
    };
    useEffect(() => {


        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);

        handleResize();
        handleScroll();

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };

    }, []);

    return(
        <div className={`navbar ${(shadowNavbar && "navbar-shadow")}`}>
            <div className="navbar-contents">
                <Link to="/">
                    <img src={assets.logo} alt="Logo" className="logo" />
                </Link>
                <ul className="navbar-menu">
                    <Link to="/" className={location.pathname==="/"?"active":""}>Accueil</Link>
                    <Link to="/produits" className={location.pathname.includes("/produits")?"active":""}>Produits</Link>
                    <Link to="/planning" className={location.pathname==="/planning"?"active":""}>Planning</Link>
                </ul>
                <div className="navbar-right">                
                    <div className="navbar-panier-icon">
                        <Link to="/panier"> <img src={assets.panier} alt="Panier button" className="panier" /></Link>
                        {Object.keys(cartItems).length > 0 && (<div className="dot"></div>) }
                    </div>
                    <img onClick={() => {setShowMobileNavbar(!showMobileNavbar)}} src={ showMobileNavbar ? assets.exit : assets.menu} alt="Menu button" className="menu" />

                    {/*<img src={assets.search} alt="Search button" className="search" />*/}
                    {/*<button>Connexion</button>*/}
                </div>
            </div>
            {showMobileNavbar && (<div className="navbar-mobile">
                <Link to="/" onClick={() => setShowMobileNavbar(false)} className={location.pathname==="/"?"active":""}>Accueil</Link>
                <Link to="/produits" onClick={() => setShowMobileNavbar(false)} className={location.pathname.includes("/produits")?"active":""}>Produits</Link>
                <Link to="/planning" onClick={() => setShowMobileNavbar(false)} className={location.pathname==="/planning"?"active":""}>Planning</Link>
            </div>)}
        </div>
    );

}


export default Navbar;
