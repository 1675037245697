import "./productadvice.css";
import { useNavigate } from 'react-router-dom';
import {Product} from "../type/Product";


type argumentsProductAdvice = {
    product:Product;
}

const ProductAdvice = ({product}:argumentsProductAdvice) => {

    const navigate = useNavigate();

    return(
        <div className="product-advice">
            <div className="product-advice-image-container">
                <img className="product-advice-image" src={product.image} alt={product.name} onClick={()=>navigate(`/produits/${product.id}`)}/>
            </div>
            <div className="product-advice-info" onClick={()=>navigate(`/produits/${product.id}`)}>
                <p className="product-advice-name">{product.name}</p>
                {/*<p className="product-advice-price">{product.price}€</p>*/}
            </div>
        </div>
    )

}

export default ProductAdvice;