import "./app.css"
import {Route, Routes} from "react-router-dom";

import Navbar from './components/Navbar/Navbar';
import Home from './pages/Home/Home';
import Products from './pages/Products/Products';
import NotFound from './pages/NotFound/NotFound';
import Footer from './components/Footer/Footer';
import Planning from './pages/Planning/Planning';
import Cart from './pages/Cart/Cart';
import Order from './pages/Order/Order';
import Confirmation from "./pages/Confirmation/Confirmation";
import ProductDetails from "./pages/ProductDetails/ProductDetails";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

function App() {

  return (
    <>
    <ScrollToTop/>
      <Navbar />
      <div className="app">
        <Routes>
            <Route path='*' element={<NotFound />} />
            <Route path="/" element={<Home/>}></Route>
            <Route path="/produits" element={<Products/>}></Route>
            <Route path="/produits/:id" element={<ProductDetails />}></Route>
            <Route path="/planning" element={<Planning/>}></Route> 
            <Route path="/panier" element={<Cart/>}></Route> 
            <Route path="/commande" element={<Order/>}></Route> 
            <Route path="/confirmation" element={<Confirmation/>}></Route> 
        </Routes>
      </div>
      <Footer/>
      <ScrollToTop/>
    </>
  );
}

export default App;
